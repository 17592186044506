module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://wallet.ilink.dev/api"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icons":[{"src":"/favicons/16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/24x24.png","sizes":"24x24","type":"image/png"},{"src":"/favicons/32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/164x164.png","sizes":"164x164","type":"image/png"},{"src":"/favicons/192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
